<template>
  <div
      class="mx-auto"
      :style="{backgroundColor:'#CDDEFF',height:height}"
    >
    <SideBar class="mt-16 color-side"/>
      <v-container>
          <v-card
            height="50px"
            elevation="6"
            class="dashboard mb-3"
            style="border-bottom: 8px solid #FAFF00;">
            <span class="pa-2 text-overline">
              <v-icon color="white" class="pa-2">mdi-monitor-dashboard </v-icon>
              Dashboard Data Number
              </span>
            <span style="position:absolute;right:0px;padding:5px;"><v-icon color="white" class="mr-1">mdi-calendar-month </v-icon><span class="mr-4 text-overline pa-2">{{time}}</span><v-icon color="white">mdi-clock-outline </v-icon><span class="text-overline pa-2">{{' '+realtime}}</span></span>
          </v-card>
                
    <v-row dense class="mb-5">
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <SyaratKetentuan/>
      </v-col>
  
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
  
        <v-row dense>
        <v-col cols="12" sm="4" md="4">
         <v-card elevation="6" color="#43658B">
          <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-subtitle class="text-overline"><span style="color:white;">Ajukan Tes Drive</span></v-list-item-subtitle>
        </v-list-item-content>
  
          <v-list-item-avatar
            tile
          >
          <v-icon color="white" large>mdi-file-plus</v-icon>
          </v-list-item-avatar>
        </v-list-item>
          <v-card-actions>
              <v-btn class="border" width="100%" small @click="ChangeURL({to:'FormTesDrive'})">
              <span style="color:black">Buat Sekarang</span>
              </v-btn>
          </v-card-actions>
          </v-card>
      </v-col>
  
      <v-col cols="12" sm="4" md="4">
          <v-card elevation="6" color="#43658B">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-subtitle class="text-overline"><span style="color:white;">Ajukan Peminjaman</span></v-list-item-subtitle>
            </v-list-item-content>
  
              <v-list-item-avatar
                tile
              >
              <v-icon color="white" large>mdi-file-plus-outline</v-icon>
              </v-list-item-avatar>
            </v-list-item>
              <v-card-actions>
                  <v-btn class="border" width="100%" small @click="ChangeURL({to:'FormPeminjaman'})">
                  <span style="color:black;">Buat Sekarang</span>
                  </v-btn>
              </v-card-actions>
              </v-card>
      </v-col>
  
      <v-col cols="12" sm="4" md="4">
          <v-card elevation="6" color="#43658B" v-show="Laporan == true">
          <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-subtitle class="text-overline"><span style="color:white;">Buat Laporan</span></v-list-item-subtitle>
          </v-list-item-content>
  
            <v-list-item-avatar
              tile
            >
            <v-icon color="white" large>mdi-chart-line</v-icon>
            </v-list-item-avatar>
          </v-list-item>
            <v-card-actions>
                <v-btn class="border" width="100%" small @click="ChangeURL({title:'Laporan',to:''})">
                <span style="color:black;">Buat Sekarang</span>
                </v-btn>
            </v-card-actions>
            </v-card>
  
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
            <v-card v-on="on" v-bind="attrs" elevation="6" color="#43658B" v-show="Laporan == false">
          <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-subtitle class="text-overline"><span style="color:white;">Buat Laporan</span></v-list-item-subtitle>
          </v-list-item-content>
  
            <v-list-item-avatar
              tile
            >
            <v-icon color="white" large>mdi-chart-line</v-icon>
            </v-list-item-avatar>
          </v-list-item>
            <v-card-actions>
                <v-btn class="border" width="100%" small disabled>
                <span style="color:black;">Buat Sekarang</span>
                </v-btn>
            </v-card-actions>
            </v-card>
              </template>
              <span>Dibatasi</span>
            </v-tooltip>
          <!-- </v-alert> -->
      </v-col>
  
            <v-col v-for="(item, i) in items"
              :key="i"
              cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
            <v-sheet
            class="rounded"
            elevation="6"
            v-show="item.isLoad == true"
              color="gray lighten-2"
            >
              <v-skeleton-loader
                class="mx-auto"
                max-height="125"
                type="card"
              ></v-skeleton-loader>
            </v-sheet>
            <v-card
            height="160px"
            v-show="item.locked == 'N' && item.isLoad == false"
            elevation="6"
            style="border-bottom: 8px solid black;">
            <!-- <v-sheet width="100%"> -->
                <v-list-item one-line class="ml-4">
                  <v-list-item-avatar
                    rounded
                    size="50"
                  >
                  <v-icon color="#a10115" style="font-size:50px;">{{item.icon}}</v-icon></v-list-item-avatar>
                <v-list-item-content class="text-end">
                <v-list-item-title ><span style="color:#a10115;font-size:30px;" v-text="item.count+' '"/></v-list-item-title>
                <v-list-item-subtitle ><span v-text="item.title" /></v-list-item-subtitle>
                <v-list-item-subtitle  style="font-size:12px;" v-show="item.title == 'Pengembalian' || item.title =='Laporan'" v-text="item.data"></v-list-item-subtitle>
                </v-list-item-content>
                <v-tooltip bottom>
                  <!-- <template v-slot:badge> -->
                <template v-slot:activator="{ on, attrs }">
                <v-badge v-on="on" v-bind="attrs" v-show="item.badge == true" icon="mdi-check" top left class="ml-6 mt-n4" color="#53BF9D"/>
                </template>
                  <span>Ada Yang Baru</span>
                </v-tooltip>
                </v-list-item>
  
                <v-card-actions style="position:absolute;bottom:0px;width:100%;">
                  <v-row dense>
                    <v-col cols="12" >
                <v-btn
                width="100%"
                height="42px"
                    outlined
                    small
                    color="#43658B"
                    @click="ChangeURL(item)"
                >
                    <span style="position:absolute;left:0px">View Details</span>
                <v-icon style="position:absolute;right:0px" small>mdi-arrow-right-circle</v-icon>
                </v-btn>
                </v-col>
                </v-row>
                </v-card-actions>
            </v-card>

            <!-- ini kalo user ga ada akses -->
            <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-card
            height="160px"
            v-on="on"
            v-bind="attrs"
            v-show="item.locked == 'Y' && item.isLoad == false"
            elevation="6"
            style="border-bottom: 8px solid grey;">
                <v-list-item one-line class="ml-4">
                  <v-list-item-avatar
                    tile
                    size="50"
                  >
                  <v-icon style="font-size:50px;">{{item.icon}}</v-icon></v-list-item-avatar>
                <v-list-item-content class="text-end">
                <v-list-item-title ><span style="color:#a10115;font-size:30px;" v-text="item.count+' '"/></v-list-item-title>
                <v-list-item-subtitle ><span v-text="item.title" /></v-list-item-subtitle>
                <v-list-item-subtitle  style="font-size:12px;" v-show="item.title == 'Pengembalian' || item.title =='Laporan'" v-text="item.data"></v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>
  
                <v-card-actions style="position:absolute;bottom:0px;width:100%;">
                  <v-row dense>
                    <v-col cols="12">
                <v-btn
                width="100%"
                height="42px"
                    outlined
                    disabled
                    small
                    color="#a10115"
                >
                    <span style="position:absolute;left:0px">View Details</span>
                <v-icon style="position:absolute;right:0px" small>mdi-arrow-right-circle</v-icon>
                </v-btn>
                </v-col>
                </v-row>
                </v-card-actions>
            </v-card>
            </template>
            <span>Dibatasi</span>
          </v-tooltip>
        </v-col>
          <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
            <v-list color="white" elevation="6">
                  <v-list-group
                  class="mt-n1"
                    no-action
                    height="100px"
                    color="black"
                  >
                  <template v-slot:activator>
                      <v-list-item-content color="white">
                        <v-list-item-title ><span style="color:#43658B;" class="text-button">ICON</span></v-list-item-title>
                      </v-list-item-content>
                    </template>
  
                    <v-list-item style="margin-left:-70px">
                      <v-list-item-content class="mt-n6">
                      <v-carousel
                      cycle
                      color="#CFDAC8"
                      height="177px"
                      :show-arrows="false"
                      hide-delimiter-background
                      >
                        <v-carousel-item>
                          <v-card
                          height="100%"
                          color="white"
                        >
                        <!-- <div class="mt-3 pa-2" v-html="icon2"></div> -->
                        <v-card-text>
                          <span class=" syarat"><b>Status Aset</b></span>
                        <h4></h4>
                        <v-icon class="mr-1 number">mdi-circle-medium</v-icon><v-icon color="#a10115">mdi-car</v-icon> : 
                          <span class="coloricon">Kendaraan operasional </span>
                          <h4></h4>
                          <v-divider/>
                          <span class="ml-15"><b class="tebal">READY</b></span>
                          <h4></h4>
                          <v-divider/>
                          <v-icon class="mr-1 number">mdi-circle-medium</v-icon><v-icon>mdi-car</v-icon> : 
                          <span class="coloricon">Kendaraan operasional</span>
                          <h4></h4>
                          <v-divider/>
                          <span class="ml-15"><b class="tebal">NOT READY</b></span>
                          <v-divider/>
                        </v-card-text>
                        </v-card>
                          
                        </v-carousel-item>
                        <v-carousel-item>
                        <v-card
                          height="100%"
                          color="white"
                        >
                        <!-- <div class="mt-3 pa-2" v-html="icon2"></div> -->
                        <v-card-text>
                        <span class=" syarat"><b>Kondisi Aset</b></span>
                        <h4></h4>
                        <v-icon class="mr-1 number">mdi-circle-medium</v-icon><v-icon>mdi-car-traction-control</v-icon> : 
                          <span class="coloricon">Kendaraan beroperasi</span>
                          <h4></h4>
                          <v-divider/>
                          <span class="coloricon ml-15">untuk <b class="tebal">TES DRIVE</b></span>
                          <h4></h4>
                          <v-divider/>
                          <v-icon class="mr-1 number">mdi-circle-medium</v-icon><v-icon>mdi-car-clock</v-icon> : 
                          <span class="coloricon">Kendaraan beroperasi</span>
                          <h4></h4>
                          <v-divider/>
                          <span class="coloricon ml-15">untuk <b class="tebal">DAILY</b></span>
                          <v-divider/>
                        </v-card-text>
                        </v-card>
                        </v-carousel-item>
                        <v-carousel-item>
                        <v-card
                          height="100%"
                          color="white"
                        >
                        <!-- <div class="mt-3 pa-2" v-html="icon3"></div> -->
                        <v-card-text>
                        <span class=" syarat"><b>Kondisi Aset</b></span>
                        <h4></h4>
                        <v-icon class="mr-1 number">mdi-circle-medium</v-icon><v-icon>mdi-car-key</v-icon> : 
                        <span class="coloricon">Kendaraan beroperasi</span>
                        <h4></h4>
                        <v-divider/>
                        <span class="coloricon ml-15">untuk <b class="tebal">DIPINJAM</b></span>
                        <v-divider/>
                        </v-card-text>
                        </v-card>
                        </v-carousel-item>
                        </v-carousel>
                        </v-list-item-content>
                        </v-list-item>
            </v-list-group>
            </v-list>
          </v-col>
          
          <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
            <v-list color="white" elevation="6" >
                  <v-list-group
                  class="mt-n1"
                    no-action
                    height="200px"
                    color="white"
                  >
                  <template v-slot:activator>
                      <v-list-item-content color="white">
                        <v-list-item-title ><span style="color:#43658B;" class="text-button">Buletin</span></v-list-item-title>
                      </v-list-item-content>
                    </template>
  
                    <v-list-item style="margin-left:-70px">
                      <v-list-item-content class="mt-n6">
                      <v-carousel
                      color="white"
                          cycle
                          height="177px"
                          :show-arrows="false"
                          hide-delimiter-background
                      >  
                      
                        <v-carousel-item>
                          <v-card
                            height="100%"
                            color="white"
                          >
                          <div class="mt-3 pa-2" v-html="Buletin"></div>
<!--                           
                            <v-card-text>
                              <span class=" syarat"><b>Informasi Terkini Oktober 2022</b></span>
                          <h4></h4>
                          <v-icon class="mr-1 number">mdi-circle-medium</v-icon><span>Mobil CR-V Nomor Polisi</span>
                          <h4></h4>
                          <v-divider/>
                          <span class="ml-7">KT 6543 RR dalam perbaikan</span>
                          <h4></h4>
                          <v-divider/>
                          <v-icon class="mr-1 number">mdi-circle-medium</v-icon>Manager Sales sedang <b class="tebal">CUTI</b> 
                          <v-divider/>
                        </v-card-text> -->
                        </v-card>
                        </v-carousel-item>
                        </v-carousel>
                        </v-list-item-content>
                        </v-list-item>
            </v-list-group>
            </v-list>
          </v-col>
        </v-row>
  
      </v-col>
      
      
    </v-row>
    <!-- <br> -->
  </v-container>
      <Laporan />
      <Footer />
  </div>
  </template>
  <script>
  const SyaratKetentuan = () => import (/* webpackChunkName: "SyaratKetentuan"*/ '@/components/SyaratKetentuan/index.vue')
  const Footer = () => import (/* webpackChunkName: "Footer"*/ '@/components/Footer/index.vue')
  const SideBar = () => import (/* webpackChunkName: "SideBar"*/ '@/components/SideBar.vue')
  const Laporan = () => import (/* webpackChunkName: "Laporan"*/ '@/components/DialogLaporan/index.vue')
  import moment from 'moment'
  import API from "@/services/http";
    export default {
      components:{
        SyaratKetentuan,SideBar,Footer,Laporan
      },
      data: () => ({
      logged:[],
      Laporan:false,
      formhide:false,
      dialog:true,
      realtime:null,
      Buletin:'',
      icon:'',
      icon2:'',
      icon3:'',
      DialogLaporan:false,
      epochtime:null,
      time:null,
      height:'720px',
      UserPengguna:null,
      Department:null,
      Departmentdata:[],
      userlogged:localStorage.getItem('userlogged'),
      accitem: [
          {
              to:'auth',
              text:'Logout'
          },
          {
              to:'setting',
              text:'Pengaturan'
          }
        ],
        items: [],
      }),
      created(){
        let winheight = window.innerHeight+50
        this.height = winheight+'px'
        window.scrollTo(0,0)
        // this.$loading(true)
        this.logged = JSON.parse(localStorage.getItem('logged'))
        this.items = this.$ItemDashboard
          this.getTime()
          this.startTime()
      },
      mounted(){
        window.scrollTo(0,0)
        if(window.innerHeight > 850){
          let elHtml = document.getElementsByTagName('html')[0]
          elHtml.style.overflowY = 'hidden'
        }
          let userlogged = localStorage.getItem('userlogged')
          let department=localStorage.getItem('departmentlogged')
          if(userlogged){
              this.dialog = false
              if(localStorage.getItem('bell') == undefined){
                this.$showDialogBell(true)
              }
              localStorage.setItem('bell','ok')
              this.department=department
          }
          this.getalldata()
      },
      methods:{
        getTime(){
            this.time = moment().locale('id').format('LL')
        },
        getalldata(){
          API.get('totalalldata/'+this.logged.id_user).then(x=>{
            //user data
            let user = this.items.findIndex(x=>x.title == 'User')
            this.items[user].locked = this.logged.level > 2 && this.logged.level < 5 ? 'N' : 'Y'
            this.items[user].count = x.data.User
            this.items[user].isLoad = false
            //aset data
            let aset = this.items.findIndex(x=>x.title == 'Aset')
            this.items[aset].count = x.data.Aset.count
            this.items[aset].isLoad = false
            this.items[aset].badge = x.data.seen == 'Y' ? true : false
            //tesdrive data
            let tesdrive = this.items.findIndex(x=>x.title == 'Tes Drive')
            this.items[tesdrive].count = x.data.TesDrive.totalform
            this.items[tesdrive].isLoad = false
            this.items[tesdrive].badge = x.data.TesDrive.seen == 'Y' ? true : false
            //peminjaman data
            let peminjaman = this.items.findIndex(x=>x.title == 'Peminjaman')
            this.items[peminjaman].count = x.data.Peminjaman.totalform
            this.items[peminjaman].isLoad = false
            this.items[peminjaman].badge = x.data.Peminjaman.seen == 'Y' ? true : false
            //pengembalian tesdrive
            let p_tesdrive = this.items.findIndex(x=>x.data == 'Tes Drive')
            // this.items[p_tesdrive].locked = u.level > 1 ? 'N' : 'Y'
            this.items[p_tesdrive].count = x.data.PengembalianTesDrive.totalform
            this.items[p_tesdrive].isLoad = false
            this.items[p_tesdrive].badge = x.data.PengembalianTesDrive.seen == 'Y' ? true : false
            // p_peminjaman
            let p_peminjaman = this.items.findIndex(x=>x.data == 'Peminjaman')
            // this.items[p_peminjaman].locked = u.level > 1 ? 'N' : 'Y'
            this.items[p_peminjaman].count = x.data.PengembalianPeminjaman.totalform
            this.items[p_peminjaman].isLoad = false
            this.items[p_peminjaman].badge = x.data.PengembalianPeminjaman.seen == 'Y' ? true : false
            this.Laporan = this.logged.level > 2 ? true : false
            this.Department = x.data.Department
              API.get('custom').then(x=>{
                this.Buletin = x.data.find(s => s.type == 'Buletin').html
                this.icon = x.data.find(s => s.type == 'Icon').html
                this.icon2 = x.data.find(s=>s.type == 'Icon2').html
                this.icon3 = x.data.find(s=>s.type == 'Icon3').html
              })
            this.$LoadingKetentuan()
          })
        },
        getDepartment(){
          API.get("/department").then(x=>{
            this.Departmentdata=x.data
            // this.$LoadingKetentuan()
            this.$LoadingKetentuan()
          })
        },
        LogOut(){
          localStorage.removeItem('userlogged')
          localStorage.removeItem('logged')
          localStorage.removeItem('departmentlogged')
          this.$router.go('/auth')
        },
        ChangeURL(x){
          if(x.title == 'Laporan'){
            this.$AllLaporan()
          }
          this.$ChangeURL(x.to)
        },
        SavePengguna(){
            if(this.UserPengguna != null && this.Department != null){
              let Department = this.Departmentdata.find(x=>x.id_department == this.Department)
              localStorage.setItem('userlogged',this.UserPengguna)
              localStorage.setItem('departmentlogged',JSON.stringify(Department))
              this.userlogged = this.UserPengguna
              this.$logged()
              this.dialog = false
            }
          },
        startTime() {
            this.realtime =  moment().locale('id').format("LTS");
            setTimeout(this.startTime, 1000);
          },
  
          checkTime(i){
            if (i < 10){
              i = "0" + i
              }
            return i
          },
          ShowBell(x){
            this.$showDialogBell(x)
          }
      }
    }
  </script>
  <style>
  .color-side{
    background-color: #FFFFFF !important;
  }
  .v-alert__icon.v-icon{
    font-size:50px;
    margin-top: 20px;
  }
  .border{
    background-color:#FAFF00 !important;
  }
  .border:hover{
    transition: border-radius 0.2s;
    border-radius: 10px;
  }
  .syarat{
    color: black !important;
  }
  .number{
  color: black !important;
  font-size: small !important;
}
.tebal{
    color: #a10115 !important;
  }
  .dashboard{
    background-color: #43658B !important;
    color: white !important;
  }
  .coloricon{
    color: black !important;
  }
  /* .theme--light.v-btn.v-btn--has-bg{
    background-color: #a10115 !important;
  } */
  /* .v-btn__content > .v-icon{
    color:#a10115 !important;
  } */
  </style>